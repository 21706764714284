import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'

import BackBar from '../../../components/BackBar'
import SubHeader from '../../../components/SubHeader'
import Heading from '../../../components/Heading'

import MenuGrid from '../../../components/MenuGrid'
import MenuWidget from '../../../components/MenuWidget'
import FileBrowserEnhancer from '../../../components/FileBrowserEnhancer'

const ReportsMenuScreen = ({
  widgets,
  onMenuItemClick,
  onAdvancedPostReport,
  onPostFileChange,
  onBackClick,
  pageTitle,
  bulkUploadsEnabled
}) => {
  return (
    <div>
      <SubHeader
        leftContent={(
          <BackBar onClick={onBackClick} />
        )}
        centerContent={(
          <Fragment>
            <Heading component='h1'>{pageTitle.title}</Heading>
            <Helmet>
              <title>{pageTitle.titleWithName}</title>
            </Helmet>
          </Fragment>
        )}
      />
      <MenuGrid widgets={widgets} renderWidget={(widget) => {
        if (widget.id === 'post') {
          return (
            <FileBrowserEnhancer
              accept={['.xml']}
              multiple={bulkUploadsEnabled}
              onChange={onPostFileChange}
              renderContent={({ onClick }) => (
                <MenuWidget
                  id={widget.id} {...widget}
                  onClick={onClick}
                />
              )}
            />
          )
        }

        if(widget.id === 'advancedPost') {
          return(
            <MenuWidget
              id={widget.id} {...widget}
              onClick={() => onAdvancedPostReport()}
          />
          )
        }

        return (
          <MenuWidget
            id={widget.id} {...widget}
            onClick={() => onMenuItemClick(widget.id)}
          />
        )
      }} />
    </div>
  )
}

ReportsMenuScreen.propTypes = {
  widgets: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    title: PropTypes.string
  })),
  pageTitle: PropTypes.shape({
    title: PropTypes.string.isRequired,
    titleWithName: PropTypes.string
  }).isRequired
}

export default ReportsMenuScreen
