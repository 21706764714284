import { connect } from 'react-redux'
import { compose, withHandlers } from 'recompose'

import UploadTranslations from './UploadTranslations'
import { selectors as organisationDetailsSelectors } from '../../../../store/modules/organisationDetails'
import { selectors as languagesSelectors } from '../../../../store/modules/languages'
import { selectors as platformSelectors } from '../../../../store/modules/platforms'
import { actions as translationActions, selectors as translationSelectors } from '../../../../store/modules/translations'
import { translations } from '../../../../config'
import withWidth from '@material-ui/core/withWidth'
import toastService from '../../../../services/toastService'
import { parseCsvString } from './utils'
import modalService from '../../../../services/modalService'
import SaveChangesModal from '../components/SaveTranslationChangesModal'

export default compose(
  connect(state => ({
    isLoading: organisationDetailsSelectors.getIsLoading(state) || languagesSelectors.getIsLoading(state),
    languages: languagesSelectors.getLanguages(state),
    organisationId: organisationDetailsSelectors.getCurrentOrganisationId(state),
    organisationTranslations: organisationDetailsSelectors.getCurrentOrganisationTranslationsForOrgDetails(state),
    existingTranslations: translationSelectors.getSearchedTranslations(state),
    pageTitle: platformSelectors.getPageTitle(state)('Manage Translations')
  })),
  withHandlers({
    onClickExport: ({ dispatch }) => () => {
      return dispatch(translationActions.generateTranslationsCSV())
        .catch(() => {
          toastService.action({
            type: 'error',
            message: translations('Manage Translations - CSV Export Error')
          })
        })
    },
    onClickImport: ({ dispatch, organisationId }) => async e => {
      const file = e.target.files[0]
      const csvString = await file.text()

      const csvTranslations = parseCsvString(csvString)

      toastService.action({
        type: 'info',
        message: translations('Manage Translations - Updates loading'),
        autoHideDuration: 3000
      })

      try {
        const translationChanges = await dispatch(translationActions.getTranslationDifferences({ organisationId, translations: csvTranslations }))
        if (translationChanges.length) {
          modalService.open({
            component: SaveChangesModal,
            largeModal: true,
            changes: Object.values(translationChanges),
            onSubmitTranslationChanges: (changes) => {
              dispatch(translationActions.uploadTranslations({ organisationId, changes, ignoreDuplicateKeys: true }))
              modalService.close()
            },
            onClose: () => modalService.close()
          })
        } else {
          toastService.action({
            type: 'success',
            message: translations('Manage Translations - No Updates'),
            autoHideDuration: 3000
          })
        }

      } catch (error) {
        toastService.action({
          type: 'error',
          message: error.message,
          autoHideDuration: 3000
        })
      }
    }
  }),
  withWidth()
)(UploadTranslations)
