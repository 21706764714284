import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useTheme, useMediaQuery, styled, Dialog, Grid, Paper } from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'

import { actions as reportDetailsActions, selectors as reportDetailsSelectors } from '../../../../store/modules/reportDetails'
import { selectors as platformSelectors } from '../../../../store/modules/platforms'

import Button from '../../../../components/Button'
import ReportDetails from './Details/ReportDetails/ReportDetails'
import ReportSummaryModalHeader from './ReportSummaryModalHeader'
import ReportConfigurationDetails from './Details/ReportConfigurationDetails'
import ExportActions from './Actions/ExportActions'
import AmendmentActions from './Actions/AmendmentActions'
import MiscActions from './Actions/MiscActions'
import SendActions from './Actions/SendActions'
import AckActions from './Actions/AckActions'
import UpdateActions from './Actions/UpdateActions'
import StatusDetails from './Details/StatusDetails'
import AttachmentDetails from './Details/AttachmentDetails'
import AdminActions from './Actions/AdminActions'
import Skeleton from '@material-ui/lab/Skeleton'
import AuditActions from './Actions/AuditActions'
import { REPORTER_STATUSES, REGULATOR_STATUSES } from '../ReportsSearchTable'
import { translations } from '../../../../config';

const Container = styled(Paper)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '80vw',
  [theme.breakpoints.down('md')]: {
    width: '90vw',
  }
}))

const Content = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  padding: theme.spacing(1),
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column'
  }
}))

const Details = styled(Grid)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start'
}))

const Actions = styled(Grid)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start'
}))

export const ActionButton = styled(Button)(({ theme }) => ({
  width: '100%',
  padding: '10px',
  display: 'flex',
  justifyContent: 'left',
  alignItems: 'center',
  textTransform: 'none'
}))

const ReportSummaryModal = (props) => {
  const {
    isOpen = true,
    handleClose,
    reportId
  } = props

  const dispatch = useDispatch()
  const report = useSelector(reportDetailsSelectors.getReport)
  const isLoading = useSelector(reportDetailsSelectors.getIsLoading)
  const isSabreReportType = useSelector(platformSelectors.getIsSabreReportType)
  const enabledDraftDeletion = useSelector(platformSelectors.getHasEnabledDeleteDrafts)
  const extReportId = useSelector(reportDetailsSelectors.getExtReportId)
  const attributes = [
    'id',
    'extReportId',
    'otherVersions',
    'revision',
    'updatedAt',
    'createdAt',
    'status',
    'userId',
    'archivedAt',
    'editable',
    'hasXML',
    'hasJSON',
    'formSchemaId',
    'formSchema',
    'fromRegulatoryAgency',
    'hasAttachments',
    'attachments',
    'hasAssessmentSchemaItems',
    'hasAttachmentSummary',
    'hasJSONReportExport',
    'workflowType',
    'typeOfReport',
    'reportSubmissionRoute',
    'organisationId',
    'locationId',
  ]

  if (isSabreReportType) {
    attributes.push('reportJSON')
  }

  useEffect(() => {
    if (isOpen && reportId) {
      dispatch(reportDetailsActions.fetchReportById({
        id: reportId,
        attributes: attributes
      }))
    }
  }, [dispatch, reportId, isOpen])

  const theme = useTheme()
  const isBelowMd = useMediaQuery(theme.breakpoints.down('md'))

  const [sections, setSections] = useState({})

  const getSection = (name) => {
    return sections[name]
  }

  const registerSection = (name) => {
    const newSections = { ...sections }
    newSections[name] = { isOpen: true }
    setSections(sections => ({ ...sections, ...newSections }))
  }

  const toggleSectionVisiblity = (name) => {
    const newSections = { ...sections }

    newSections[name] = {
      ...newSections[name],
      isOpen: !newSections[name]?.isOpen
    }

    setSections(newSections)
  }

  const toggleAllSections = ({ open }) => {
    const sectionKeys = _.keys(sections)
    const newSections = {}
    for (const sectionKey of sectionKeys) {
      newSections[sectionKey] = { ...sections[sectionKey], isOpen: open }
    }
    setSections(newSections)
  }

  const regulatorStatus = _.get(report, 'reportJSON.regulatorStatus')
  const reporterStatus = _.get(report, 'reportJSON.reporterStatus')

  const isSabreDraft = isSabreReportType && regulatorStatus === REGULATOR_STATUSES.NOT_RECEIVED && reporterStatus == REPORTER_STATUSES.SUBMIT_NOTIFICATION
  const hideReportId = isSabreDraft
  const reportIsDraft = report && report.status === 'draft'

  const renderSkeleton = () => {
    return (
      <Grid container>
        <Skeleton variant="rectangular" width={'100%'} height={80} animation="pulse" style={{ marginBottom: 30 }} />
        <Details xs={12} lg={8} style={{ padding: '0 30px' }}>
          <Skeleton variant="rectangular" height={200} width={'100%'} animation="pulse" style={{ marginBottom: 10 }} />
          <Skeleton variant="rectangular" height={150} width={'100%'} animation="pulse" style={{ marginBottom: 10 }} />
          <Skeleton variant="rectangular" height={150} width={'100%'} animation="pulse" style={{ marginBottom: 10 }} />
        </Details>
        <Actions xs={12} lg={5} style={{ paddingRight: 30 }}>
          <Skeleton variant="rectangular" height={125} width={'100%'} animation="pulse" style={{ marginBottom: 10 }} />
          <Skeleton variant="rectangular" height={125} width={'100%'} animation="pulse" style={{ marginBottom: 10 }} />
          <Skeleton variant="rectangular" height={100} width={'100%'} animation="pulse" style={{ marginBottom: 10 }} />
        </Actions>
      </Grid>
    )
  }
  return (
    <Dialog
      open={isOpen}
      maxWidth={'xl'}
      onBackdropClick={handleClose}
      PaperComponent={Container}
    >
      {isLoading && renderSkeleton()}
      {!isLoading &&
        <Grid container>
          <ReportSummaryModalHeader
            handleClose={handleClose}
            toggleAllSections={toggleAllSections}
            isBelowMd={isBelowMd}
            reportId={hideReportId ? translations('Yet to be assigned') : extReportId}
            showCopyButton={!hideReportId}
          />
          <Content>
            {renderDetails({ report, registerSection, toggleSectionVisiblity, getSection })}
            {renderActions({
              report,
              registerSection,
              toggleSectionVisiblity,
              getSection,
              showExportActions: !isSabreDraft,
              isSabreReportType,
              handleClose,
              reportIsDraft,
              enabledDraftDeletion
            })}
          </Content>
        </Grid>
      }
    </Dialog>

  )
}

const renderDetails = ({ report, registerSection, toggleSectionVisiblity, getSection }) => {
  return (
    <Details xs={12} lg={8}>
      <ReportDetails
        registerSection={registerSection}
        toggleSectionVisiblity={toggleSectionVisiblity}
        getSection={getSection}
        report={report}
      />
      <ReportConfigurationDetails
        registerSection={registerSection}
        toggleSectionVisiblity={toggleSectionVisiblity}
        getSection={getSection}
        report={report}
      />
      <StatusDetails
        registerSection={registerSection}
        toggleSectionVisiblity={toggleSectionVisiblity}
        getSection={getSection}
        report={report}
      />
      <AttachmentDetails
        registerSection={registerSection}
        toggleSectionVisiblity={toggleSectionVisiblity}
        getSection={getSection}
        report={report}
      />
    </Details>
  )
}

const renderActions = ({
  report,
  registerSection,
  toggleSectionVisiblity,
  getSection,
  showExportActions = true,
  isSabreReportType,
  handleClose,
  reportIsDraft,
  enabledDraftDeletion
}) => {
  return (
    <Actions xs={12} lg={4}>
      {showExportActions &&
        <ExportActions
          registerSection={registerSection}
          toggleSectionVisiblity={toggleSectionVisiblity}
          getSection={getSection}
          report={report}
        />
      }
      <UpdateActions
        canDeleteDraft={reportIsDraft && enabledDraftDeletion}
        registerSection={registerSection}
        toggleSectionVisiblity={toggleSectionVisiblity}
        getSection={getSection}
        handleClose={handleClose}
      />
      <AmendmentActions
        registerSection={registerSection}
        toggleSectionVisiblity={toggleSectionVisiblity}
        getSection={getSection}
        report={report}
      />
      <MiscActions
        registerSection={registerSection}
        toggleSectionVisiblity={toggleSectionVisiblity}
        getSection={getSection}
        report={report}
      />
      <SendActions
        registerSection={registerSection}
        toggleSectionVisiblity={toggleSectionVisiblity}
        getSection={getSection}
        report={report}
      />
      <AckActions
        registerSection={registerSection}
        toggleSectionVisiblity={toggleSectionVisiblity}
        getSection={getSection}
        report={report}
      />
      <AdminActions
        registerSection={registerSection}
        toggleSectionVisiblity={toggleSectionVisiblity}
        getSection={getSection}
        report={report}
      />
      <AuditActions
        registerSection={registerSection}
        toggleSectionVisiblity={toggleSectionVisiblity}
        getSection={getSection}
        report={report}
      />
    </Actions>
  )
}

export default ReportSummaryModal
