import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import Skeleton from '@material-ui/lab/Skeleton'
import { Table, TableHead, TableRow, TableCell } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import _ from 'lodash'

const StyledTableHead = withStyles(theme => ({
  root: {
    backgroundColor: theme.palette.grey[200],
  },
}))(TableHead)

const StyledTableCell = withStyles({
  root: {
    padding: '8px 12px', 
  },
})(TableCell)

const StyledTableHeaderRow = withStyles({
  root: {
    height: '40px',
  },
})(TableRow)

const StyledTableRow = withStyles({
  root: {
    height: '50px',
  },
})(TableRow)

const DataTableSkeleton = ({ colCount, rowCount, showHeader = false }) => {
  const renderRows = () => (
    <Fragment>
      {_.map(_.times(rowCount), (rowIndex) => (
        <StyledTableRow key={rowIndex}>
          {_.map(_.times(colCount), (colIndex) => (
            <StyledTableCell key={colIndex}>
              <Skeleton variant="rect" width="100%" height={16} />
            </StyledTableCell>
          ))}
        </StyledTableRow>
      ))}
    </Fragment>
  )

  if (showHeader) {
    return (
      <Table>
        <StyledTableHead>
          <StyledTableHeaderRow>
            {_.map(_.times(colCount), (colIndex) => (
              <StyledTableCell key={colIndex}>
                <Skeleton variant="text" width="90%" />
              </StyledTableCell>
            ))}
          </StyledTableHeaderRow>
        </StyledTableHead>
        {renderRows()}
      </Table>
    )
  }

  return renderRows()
}

DataTableSkeleton.defaultProps = {
  colCount: 5,
  rowCount: 5,
}

DataTableSkeleton.propTypes = {
  colCount: PropTypes.number,
  rowCount: PropTypes.number,
  showHeader: PropTypes.bool,
}

export default DataTableSkeleton
