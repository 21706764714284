import { connect } from 'react-redux'
import { goBack, push } from 'connected-react-router'
import { compose, withProps, withHandlers } from 'recompose'
import { translations, deriveTranslationFromError } from '../../../config'

import ReportsMenuScreen from './ReportsMenuScreen'
import { selectors as authSelectors } from '../../../store/modules/auth'
import modalService from '../../../services/modalService'
import { actions as fileManagerActions } from '../../../store/modules/fileManager'
import { selectors as formViewSelectors } from '../../../store/modules/formViews'
import { selectors as platformSelectors } from '../../../store/modules/platforms'
import fp from 'lodash/fp'

const MAX_FILE_UPLOAD_LIMIT = 100

const enhancer = compose(
  connect(state => ({
    organisationId: authSelectors.getSelectedOrganisation(state).id,
    userId: authSelectors.getActiveUserId(state),
    userRole: authSelectors.getRoleValue(state),
    canPostReports: platformSelectors.getHasPostReportPermissions(state),
    pageTitle: platformSelectors.getPageTitle(state)('App Name - Report Submission'),
    formViews: formViewSelectors.getAllFormViews(state),
    bulkUploadsEnabled: platformSelectors.canBulkUploadReports(state),
    canAdvancedPostReport: platformSelectors.getHasAdvancedPostReportPermissions(state)
  })),
  withProps(({ canPostReports, canAdvancedPostReport }) => ({
    widgets: [{ id: 'new', title: 'New Report', image: 'newReportImg', icon: 'newReportIcon' }]
      .concat(canPostReports ? [{ id: canAdvancedPostReport ? 'advancedPost' : 'post', title: 'Post XML Reports', image: 'postReportImg', icon: 'postReportIcon' }] : [])
  })),
  withHandlers({
    onBackClick: ({ dispatch }) => () => {
      dispatch(goBack())
    },
    onMenuItemClick: ({ dispatch, formViews, userRole }) => id => {
      dispatch(push(`/select-report`))
    },
    onAdvancedPostReport: ({ dispatch, formViews, userRole }) => id => {
      dispatch(push('/post-report'))
    },
    onPostFileChange: ({ userId, organisationId, dispatch }) => (e) => {
      const files = e.target.files
      const fileCount = fp.size(files)
      if (fileCount === 0) {
        return
      }
      if (fileCount > MAX_FILE_UPLOAD_LIMIT) {
        return modalService.action({
          title: translations('Post Report Fail Title'),
          text: translations('File Manager - Maximum File Upload Limit', { limit: MAX_FILE_UPLOAD_LIMIT }),
          disableBackdropClick: true,
          actions: [
            {
              success: true,
              text: translations('Continue'),
              onClick: () => {},
              primary: true
            }
          ]
        })
      }
      const formattedFiles = []
      for(const file of files) {
        const formattedFile = {
          file: file,
          attachments: []
        }
        formattedFiles.push(formattedFile)
      }
      return dispatch(fileManagerActions.setUploadFile({ files: formattedFiles, userId, organisationId }))
    }
  })
)

export default enhancer(ReportsMenuScreen)
